var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ApolloQuery', {
    attrs: {
      "query": require('#/graphql/user/show.gql'),
      "variables": _vm.variables
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _ref$result = _ref.result,
          loading = _ref$result.loading,
          error = _ref$result.error,
          data = _ref$result.data;
        return [loading ? _c('TalpaLoaderWrapper', {
          attrs: {
            "transparent": true
          }
        }) : error ? _c('ErrorAtom', {
          attrs: {
            "error": error
          }
        }) : data ? _c('UserAttributesMolecule', {
          attrs: {
            "user": data.user
          }
        }) : _c('EntityNotFoundAtom', {
          attrs: {
            "entityType": 'User',
            "entityId": _vm.userId
          }
        })];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }