var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('RelationManagerTableDefaultActionsAtom', [_vm.row.hasEdit || _vm.hasEdit ? _c('Edit2Icon', {
    attrs: {
      "size": "18"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('edit');
      }
    }
  }) : _vm._e(), _vm.row.hasCopy || _vm.hasCopy ? _c('CopyIcon', {
    attrs: {
      "size": "18"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('copy');
      }
    }
  }) : _vm._e(), _vm.row.hasDelete || _vm.hasDelete ? _c('TrashIcon', {
    attrs: {
      "size": "18"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('delete');
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }