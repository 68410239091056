var render = function () {
  var _this$$route, _this$$route$query, _this$$route2, _this$$route2$query, _this$$route3, _this$$route3$query;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('PanelMolecule', {
    attrs: {
      "title": _vm.title,
      "hasMargin": true,
      "stickyHeader": true,
      "noGap": true
    }
  }, [_c('TabRoutesMolecule', {
    attrs: {
      "slot": "subheader",
      "routes": _vm.routes
    },
    slot: "subheader"
  }), _c('template', {
    slot: "main"
  }, [_vm.$apollo.loading ? _c('TalpaLoaderWrapper') : ((_this$$route = this.$route) === null || _this$$route === void 0 ? void 0 : (_this$$route$query = _this$$route.query) === null || _this$$route$query === void 0 ? void 0 : _this$$route$query.section) === undefined ? _c('UserOverviewTemplate', {
    attrs: {
      "userId": _vm.$route.params.id
    }
  }) : ((_this$$route2 = this.$route) === null || _this$$route2 === void 0 ? void 0 : (_this$$route2$query = _this$$route2.query) === null || _this$$route2$query === void 0 ? void 0 : _this$$route2$query.section) === 'access' ? _c('UserAccessTemplate', {
    attrs: {
      "userId": _vm.$route.params.id
    }
  }) : ((_this$$route3 = this.$route) === null || _this$$route3 === void 0 ? void 0 : (_this$$route3$query = _this$$route3.query) === null || _this$$route3$query === void 0 ? void 0 : _this$$route3$query.section) === 'dashboards' ? _c('UserDashboardsTemplate', {
    attrs: {
      "userId": _vm.$route.params.id
    }
  }) : _vm._e()], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }