<template>
  <RelationManagerTableDefaultActionsAtom>
    <Edit2Icon size="18" v-if="row.hasEdit || hasEdit" @click="$emit('edit')" />
    <CopyIcon size="18" v-if="row.hasCopy || hasCopy" @click="$emit('copy')" />
    <TrashIcon size="18" v-if="row.hasDelete || hasDelete" @click="$emit('delete')" />
  </RelationManagerTableDefaultActionsAtom>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { Edit2Icon, CopyIcon, TrashIcon } from 'vue-feather-icons'

const RelationManagerTableDefaultActionsAtom = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  svg {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
    &:hover {
      color: ${({ theme }) => theme.colors.primaryLighter};
    }
  }
`

export default {
  props: {
    row: {
      type: Object,
      required: true,
    },
    hasEdit: {
      type: Boolean,
      default: true,
    },
    hasCopy: {
      type: Boolean,
      default: false,
    },
    hasDelete: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    RelationManagerTableDefaultActionsAtom,
    Edit2Icon,
    CopyIcon,
    TrashIcon,
  },
}
</script>
