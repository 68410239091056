<template>
  <EntityNotFoundAtom>
    <span>
      <span class="entity-type">{{ entityType }}</span>
      <span class="muted">with the id</span>
      <span class="entity-id">'{{ entityId }}'</span>
      <span class="muted">was not found.</span>
    </span>
  </EntityNotFoundAtom>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const EntityNotFoundAtom = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`

export default {
  props: {
    entityType: {
      type: String,
      required: true,
    },
    entityId: {
      type: String,
      required: true,
    },
  },
  components: {
    EntityNotFoundAtom,
  },
}
</script>
