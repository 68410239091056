<template>
  <UserDashboardsFooterMolecule>
    <slot />
  </UserDashboardsFooterMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const UserDashboardsFooterMolecule = styled('footer')`
  display: flex;
  .search-and-select {
    flex-grow: 1;
    min-width: 24rem;
  }
`

export default {
  components: {
    UserDashboardsFooterMolecule,
  },
}
</script>
