var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isTalpaEmail && _vm.isArchon ? _c('RelationManagerListMolecule', {
    attrs: {
      "title": _vm.title,
      "relations": _vm.userTalpaRoleGroups,
      "addables": _vm.talpaRoleGroups,
      "addablesLoading": _vm.$apollo.queries.loading,
      "itemLabelKey": 'name'
    },
    on: {
      "select": _vm.addGroupToUser,
      "remove": _vm.removeGroupFromUser
    }
  }) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }