var render = function () {
  var _vm$label;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('UserOrganizationAtom', [_c('router-link', {
    attrs: {
      "to": _vm.item.linkTo
    }
  }, [_c('TextOverflowEllipsisAtom', {
    staticClass: "label",
    attrs: {
      "text": (_vm$label = _vm.label) !== null && _vm$label !== void 0 ? _vm$label : _vm.item.id
    }
  })], 1), _c('div'), _c('Multiselect', {
    staticClass: "select-role",
    attrs: {
      "track-by": "id",
      "value": _vm.selectedRole,
      "label": 'label',
      "options": _vm.roles,
      "searchable": false,
      "multiple": false,
      "showLabels": false
    },
    on: {
      "select": function select($event) {
        return _vm.$emit('update', Object.assign({}, _vm.item, {
          role: $event.id
        }));
      }
    }
  }), _vm.hasRemove ? _c('IconButtonAtom', {
    on: {
      "click": function click($event) {
        return _vm.$emit('remove');
      }
    }
  }, [_c('XIcon', {
    attrs: {
      "size": "16"
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }