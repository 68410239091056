var render = function () {
  var _vm$inherited, _vm$inherited$product, _vm$inherited2, _vm$inherited2$produc, _vm$inherited3, _vm$inherited4, _vm$inherited4$acquis, _vm$inherited5, _vm$inherited5$organi;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('InheritedByAcquistionAtom', [_c('TextOverflowEllipsisAtom', {
    staticClass: "label",
    attrs: {
      "text": _vm.inherited.label
    }
  }), _c('div', {
    staticClass: "product"
  }, [_c('span', {
    staticClass: "muted"
  }, [_vm._v("via")]), _c('EntityLinkAtom', {
    attrs: {
      "type": 'Product',
      "id": (_vm$inherited = _vm.inherited) === null || _vm$inherited === void 0 ? void 0 : (_vm$inherited$product = _vm$inherited.product) === null || _vm$inherited$product === void 0 ? void 0 : _vm$inherited$product.id,
      "label": "".concat((_vm$inherited2 = _vm.inherited) === null || _vm$inherited2 === void 0 ? void 0 : (_vm$inherited2$produc = _vm$inherited2.product) === null || _vm$inherited2$produc === void 0 ? void 0 : _vm$inherited2$produc.name, " - ").concat((_vm$inherited3 = _vm.inherited) === null || _vm$inherited3 === void 0 ? void 0 : _vm$inherited3.tierLevel)
    }
  })], 1), _c('div', {
    staticClass: "acquisition"
  }, [_c('span', {
    staticClass: "muted"
  }, [_vm._v("acquired by")]), _c('EntityLinkAtom', {
    attrs: {
      "type": 'Acquisition',
      "id": (_vm$inherited4 = _vm.inherited) === null || _vm$inherited4 === void 0 ? void 0 : (_vm$inherited4$acquis = _vm$inherited4.acquisition) === null || _vm$inherited4$acquis === void 0 ? void 0 : _vm$inherited4$acquis.id,
      "label": (_vm$inherited5 = _vm.inherited) === null || _vm$inherited5 === void 0 ? void 0 : (_vm$inherited5$organi = _vm$inherited5.organization) === null || _vm$inherited5$organi === void 0 ? void 0 : _vm$inherited5$organi.name
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }