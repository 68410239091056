<template>
  <RelationManagerListMolecule
    :title="`Subsidiaries (${userSubsidiaries.length})`"
    :relations="userSubsidiaries"
    :addables="addableSubsidiaries"
    :addablesLoading="$apollo.queries.loading"
    :itemLabelKey="'label'"
    @select="createMembership"
    @remove="confirmDeleteMembership"
  />
</template>

<script>
import { FlashMessages } from '@common/singletons'
import RelationManagerListMolecule from '@/components/Atomic/Molecules/RelationManagerListMolecule.vue'

import USER_QUERY from '#/graphql/user/show.gql'
import USER_ADDABLE_SUBSIDIARIES_QUERY from '#/graphql/operations/subsidiaries/userAddableSubsidiariesQuery.gql'
import CREATE_SUBSIDIARY_MEMBERSHIP_MUTATION from '#/graphql/organizations/subsidiary/membership/create.gql'
import DELETE_SUBSIDIARY_MEMBERSHIP_MUTATION from '#/graphql/organizations/subsidiary/membership/delete.gql'

export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  components: {
    RelationManagerListMolecule,
  },
  data() {
    return {
      talpaRoleGroups: [],
      subsidiaries: [],
      searchString: '',
    }
  },
  computed: {
    userOrganizationIds() {
      return this.user.memberships.map(m => m.organization.id)
    },
    userSubsidiaries() {
      return this.user?.memberships.flatMap(organizationMembership =>
        organizationMembership.subsidiaryMemberships.map(sm => ({
          ...sm.subsidiary,
          subsidiaryMembershipId: sm.id,
          organization: organizationMembership.organization,
          label: `${organizationMembership.organization.alias} - ${sm.subsidiary.name}`,
          linkTo: { name: 'subsidiary', params: { id: sm.subsidiary.id } },
        })),
      )
    },
    addableSubsidiaries() {
      return this.subsidiaries.map(m => ({ ...m, label: `${m.organization.alias} - ${m.name}` }))
    },
  },
  methods: {
    async createMembership($event) {
      try {
        await this.$apollo.mutate({
          mutation: CREATE_SUBSIDIARY_MEMBERSHIP_MUTATION,
          variables: {
            data: {
              userId: this.userId,
              subsidiary: {
                connect: {
                  id: $event.id,
                },
              },
              role: 'USER',
            },
          },
          update: (store, { data }) => {
            const { user } = store.readQuery({
              query: USER_QUERY,
              variables: {
                where: {
                  id: this.userId,
                },
              },
            })
            const membership = user.memberships.find(f => f.organization.id === data.createSubsidiaryMembership.subsidiary.organization.id)
            membership.subsidiaryMemberships.push(data.createSubsidiaryMembership)
            store.writeQuery({
              query: USER_QUERY,
              variables: {
                where: {
                  id: this.$route.params.id,
                },
              },
              data: {
                user,
              },
            })
          },
        })
        FlashMessages.$emit('success', `Successfully added user to subsidiary '${$event.name}'`, {
          timeout: 1500,
        })
        this.$apollo.queries.user.refetch()
      } catch (err) {
        FlashMessages.$emit('error', err)
        throw err
      }
    },
    confirmDeleteMembership($event) {
      this.$root.$emit('activateOverlay', 'ConfirmDeleteOverlay', {
        type: 'Membership',
        onConfirm: this.deleteMembership,
        onConfirmArgs: $event,
      })
    },
    async deleteMembership($event) {
      try {
        await this.$apollo.mutate({
          mutation: DELETE_SUBSIDIARY_MEMBERSHIP_MUTATION,
          variables: {
            where: {
              id: $event.subsidiaryMembershipId,
            },
          },
          update: (store, { data }) => {
            const { user } = store.readQuery({
              query: USER_QUERY,
              variables: {
                where: {
                  id: this.userId,
                },
              },
            })
            const membership = user.memberships.find(f => f.subsidiaryMemberships.some(s => s.id === data.deleteSubsidiaryMembership.id))
            membership.subsidiaryMemberships = membership.subsidiaryMemberships.filter(f => f.id !== data.deleteSubsidiaryMembership?.id)
            store.writeQuery({
              query: USER_QUERY,
              variables: {
                where: {
                  id: this.userId,
                },
              },
              data: {
                user,
              },
            })
          },
        })
        FlashMessages.$emit('success', `Successfully removed user from subsidiary '${$event.name}'`, {
          timeout: 1500,
        })
        await this.$apollo.queries.user.refetch()
        return true
      } catch (err) {
        FlashMessages.$emit('error', err)
        throw err
      }
    },
  },
  apollo: {
    user: {
      query: USER_QUERY,
      variables() {
        return {
          where: {
            id: this.userId,
          },
        }
      },
    },
    subsidiaries: {
      query: USER_ADDABLE_SUBSIDIARIES_QUERY,
      variables() {
        return {
          where: {
            organization: {
              id_in: this.userOrganizationIds,
            },
          },
        }
      },
    },
  },
}
</script>
