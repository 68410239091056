var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('RelationManagerListMolecule', {
    attrs: {
      "title": "Organizations (".concat(_vm.userOrganizations.length, ")"),
      "itemComponent": _vm.itemComponent,
      "relations": _vm.userOrganizations,
      "addables": _vm.addableOrganizations,
      "addablesLoading": _vm.$apollo.queries.loading,
      "itemLabelKey": 'label'
    },
    on: {
      "select": _vm.createMembership,
      "update": _vm.updateMembership,
      "remove": _vm.confirmDeleteMembership
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }