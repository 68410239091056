<template>
  <TabRoutesMolecule>
    <router-link v-for="route in routes" :key="route.id" :to="route.to" :class="{ 'is-forced-active': route.isForcedActive }">
      {{ route.label }}
    </router-link>
  </TabRoutesMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const TabRoutesMolecule = styled('menu')`
  margin: 0;
  padding: 0;
  padding-top: 1rem;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  background: ${({ theme }) => theme.colors.archonBlack};

  > a {
    display: flex;
    justify-content: center;
    padding-bottom: 0.5rem;
    font-weight: bold;
    border-bottom: 2px solid ${({ theme }) => theme.colors.archonBlack};
    &.router-link-exact-active,
    &.is-forced-active {
      color: ${({ theme }) => theme.colors.primary};
      border-color: ${({ theme }) => theme.colors.primary};
    }
  }
`

export default {
  props: {
    routes: {
      type: Array,
      required: true,
    },
  },
  components: {
    TabRoutesMolecule,
  },
}
</script>
