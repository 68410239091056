<template>
  <GridMolecule>
    <UserAttributesOrganism class="span-2" :userId="userId" />
    <UserOrganisationsOrganism :userId="userId" />
    <UserSubsidiariesOrganism :userId="userId" />
    <UserTalpaRolesOrganism :userId="userId" />
  </GridMolecule>
</template>

<script>
import GridMolecule from '@/components/Atomic/Molecules/GridMolecule'
import UserAttributesOrganism from '../Organisms/UserAttributesOrganism'
import UserTalpaRolesOrganism from '../Organisms/UserTalpaRolesOrganism'
import UserOrganisationsOrganism from '../Organisms/UserOrganisationsOrganism'
import UserSubsidiariesOrganism from '../Organisms/UserSubsidiariesOrganism'

export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  components: {
    GridMolecule,
    UserAttributesOrganism,
    UserTalpaRolesOrganism,
    UserOrganisationsOrganism,
    UserSubsidiariesOrganism,
  },
}
</script>
