var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('UserAccessTemplate', [_c('UserAccessOrganism', {
    attrs: {
      "userId": _vm.userId,
      "type": 'permission'
    }
  }), _c('UserAccessOrganism', {
    attrs: {
      "userId": _vm.userId,
      "type": 'assetDimension'
    }
  }), _c('UserAccessOrganism', {
    attrs: {
      "userId": _vm.userId,
      "type": 'widgetType'
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }