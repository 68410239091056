var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$apollo.queries.user.loading ? _c('TalpaLoaderWrapper', {
    attrs: {
      "transparent": true
    }
  }) : _vm.hasErrors ? _c('div', {
    staticClass: "errors"
  }, _vm._l(_vm.errors, function (error, i) {
    return _c('ErrorAtom', {
      key: i,
      attrs: {
        "error": error
      }
    });
  }), 1) : _vm.user ? _c('ModelManagerTableMolecule', {
    attrs: {
      "title": "Dashboards owned by user",
      "count": _vm.rows.length,
      "columns": _vm.columns,
      "hasEdit": false,
      "rows": _vm.rows
    },
    on: {
      "selected-row-change": _vm.handleSelectedRowChange,
      "select-all-change": _vm.handleSelectAllChange,
      "delete": _vm.confirmDeleteDashboard
    }
  }, [_c('UserDashboardsFooterMolecule', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('IconButtonAtom', {
    attrs: {
      "disabled": _vm.selectedRowIds.length === 0
    },
    on: {
      "click": _vm.confirmDeleteSelectedDashboards
    }
  }, [false ? _c('TrashIcon') : _vm._e(), _c('span', [_vm._v("delete selected")])], 1), _c('SearchAndSelectMolecule', {
    attrs: {
      "selectables": _vm.copyableDashboards,
      "loading": _vm.copyableDashboardsLoading
    },
    on: {
      "select": _vm.copySelectedDashboard,
      "search-change": function searchChange($event) {
        _vm.copyableDashboardsSearchQuery = $event;
      }
    }
  }, [_c('span', {
    attrs: {
      "slot": "aside"
    },
    slot: "aside"
  }, [_vm._v("Copy")])])], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }