<template>
  <GridMolecule :gap="1">
    <RelationManagerListMolecule
      :title="`Manual ${relationsKey}s (${relations?.length})`"
      :relations="relations"
      :itemLabelKey="'name'"
      :addables="addables"
      @select="handleSelect"
      @remove="handleRemove"
    />
    <EntityListMolecule>
      <LabelAtom slot="header" :label="`Inherited ${relationsKey}s (${inheritedItems.length})`" />
      <InheritedByAcquistionAtom v-for="(inheritedItem, i) in inheritedItems" :key="i" :inherited="inheritedItem" />
    </EntityListMolecule>
  </GridMolecule>
</template>

<script>
import { FlashMessages } from '@common/singletons'

import GridMolecule from '@/components/Atomic/Molecules/GridMolecule'
import RelationManagerListMolecule from '@/components/Atomic/Molecules/RelationManagerListMolecule'
import EntityListMolecule from '@/components/Atomic/Molecules/EntityListMolecule'
import LabelAtom from '@/components/Atomic/Atoms/LabelAtom'
import InheritedByAcquistionAtom from '../Atoms/InheritedByAcquistionAtom'

import CREATE_USER_PERMISSION from '#/graphql/user/userPermission/create.gql'
import DELETE_USER_PERMISSION from '#/graphql/user/userPermission/delete.gql'
import CREATE_USER_ASSET_DIMENSION from '#/graphql/user/userAssetDimension/create.gql'
import DELETE_USER_ASSET_DIMENSION from '#/graphql/user/userAssetDimension/delete.gql'
import CREATE_USER_WIDGET_TYPE from '#/graphql/user/userWidgetType/create.gql'
import DELETE_USER_WIDGET_TYPE from '#/graphql/user/userWidgetType/delete.gql'
import USER_ACCESS_QUERY from '#/graphql/user/userAccessQuery.gql'
// import USER_QUERY from '#/graphql/user/show.gql'

export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  components: {
    GridMolecule,
    LabelAtom,
    RelationManagerListMolecule,
    EntityListMolecule,
    InheritedByAcquistionAtom,
  },
  data() {
    return {
      user: undefined,
      error: undefined,
      addables: [],
    }
  },
  computed: {
    relationsKey() {
      return this.type.charAt(0).toUpperCase() + this.type.slice(1)
    },
    relations() {
      return this.user?.[`user${this.relationsKey}s`]?.map(m => m[this.type]).sort((a, b) => a.name.localeCompare(b.name)) ?? []
    },
    inheritedItems() {
      const inheritedItems =
        this.user?.memberships.reduce((acc, item) => {
          item.organization.acquiredProductTierLevels.forEach(acquiredProductTierLevel => {
            const items = acquiredProductTierLevel.productTierLevel[`${this.type}Ids`].map(id => {
              const found = this.addables.find(f => f.id === id)
              const label = found?.name ?? `deleted ${this.title}`
              return {
                label,
                product: acquiredProductTierLevel.productTierLevel.product,
                tierLevel: acquiredProductTierLevel.productTierLevel.tierLevel,
                acquisition: acquiredProductTierLevel.acquisition,
                organization: {
                  id: item.organization.id,
                  name: item.organization.name,
                },
              }
            })
            acc.push(...items)
          })
          return acc
        }, []) ?? []
      return inheritedItems.sort((a, b) => a.label.localeCompare(b.label))
    },
  },
  methods: {
    async handleSelect($event) {
      try {
        const mutationMap = {
          permission: CREATE_USER_PERMISSION,
          assetDimension: CREATE_USER_ASSET_DIMENSION,
          widgetType: CREATE_USER_WIDGET_TYPE,
        }
        const mutationResultKeyMap = {
          permission: 'createUserPermission',
          assetDimension: 'createUserAssetDimension',
          widgetType: 'createUserWidgetType',
        }
        await this.$apollo.mutate({
          mutation: mutationMap[this.type],
          variables: {
            data: {
              userId: this.userId,
              [this.type]: {
                connect: {
                  id: $event.id,
                },
              },
            },
          },
          update: (store, { data }) => {
            const userAccessQuery = store.readQuery({
              query: USER_ACCESS_QUERY,
              variables: {
                where: {
                  id: this.userId,
                },
              },
            })

            userAccessQuery?.user?.[`user${this.relationsKey}s`].push(data?.[mutationResultKeyMap[this.type]])

            store.writeQuery({
              query: USER_ACCESS_QUERY,
              variables: {
                where: {
                  id: this.userId,
                },
              },
              data: userAccessQuery,
            })
          },
        })
        FlashMessages.$emit('success', `Successfully added ${$event.name} to user.`, {
          timeout: 1500,
        })
      } catch (err) {
        FlashMessages.$emit('error', err)
        throw err
      }
    },
    async handleRemove($event) {
      const found = this.user?.[`user${this.relationsKey}s`].find(f => f[this.type]?.id === $event.id)
      if (!found) {
        throw new Error(`could not find ${this.type}:${$event.id} in user${this.relationsKey}s`)
      }
      try {
        const mutationMap = {
          permission: DELETE_USER_PERMISSION,
          assetDimension: DELETE_USER_ASSET_DIMENSION,
          widgetType: DELETE_USER_WIDGET_TYPE,
        }
        await this.$apollo.mutate({
          mutation: mutationMap[this.type],
          variables: {
            where: {
              id: found.id,
            },
          },
          update: store => {
            const userAccessQuery = store.readQuery({
              query: USER_ACCESS_QUERY,
              variables: {
                where: {
                  id: this.userId,
                },
              },
            })

            userAccessQuery.user[`user${this.relationsKey}s`] = userAccessQuery?.user?.[`user${this.relationsKey}s`].filter(
              f => f.id !== found.id,
            )

            store.writeQuery({
              query: USER_ACCESS_QUERY,
              variables: {
                where: {
                  id: this.userId,
                },
              },
              data: userAccessQuery,
            })
          },
        })
        FlashMessages.$emit('success', `Successfully removed ${$event.name} from user.`, {
          timeout: 1500,
        })
      } catch (err) {
        FlashMessages.$emit('error', err)
        throw err
      }
    },
  },
  apollo: {
    userAccess: {
      query: USER_ACCESS_QUERY,
      variables() {
        return {
          where: {
            id: this.userId,
          },
        }
      },
      manual: true,
      result({ data, error }) {
        if (error) {
          this.error = error
          this.addables = []
          this.user = undefined
        } else {
          this.user = data.user
          this.addables = data[`${this.type}s`]?.sort((a, b) => a?.name.localeCompare(b?.name))
          this.error = undefined
        }
      },
    },
  },
}
</script>
