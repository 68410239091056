<template>
  <SearchAndSelectMolecule class="search-and-select">
    <aside v-if="hasSlotAside">
      <slot name="aside" />
    </aside>
    <Multiselect
      :options="selectables"
      :show-labels="false"
      :searchable="true"
      :internalSearch="false"
      :trackBy="trackBy"
      :label="label"
      :placeholder="placerholder"
      :closeOnSelect="closeOnSelect"
      :clearOnSelect="clearOnSelect"
      :openDirection="openDirection"
      :disabled="disabled"
      :loading="loading"
      @select="$emit('select', $event)"
      @search-change="$emit('search-change', $event)"
      @open="multiselectIsOpen = true"
      @close="multiselectIsOpen = false"
    >
      <span slot="noOptions">No options.</span>
      <span slot="noResult">No results.</span>
    </Multiselect>
  </SearchAndSelectMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import Multiselect from 'vue-multiselect'

const SearchAndSelectMolecule = styled('div')`
  display: grid;
  grid-template-columns: min-content minmax(12rem, 1fr);
  grid-template-rows: min-content;
  background: ${({ theme }) => theme.colors.archonBlackTransparent};
  border-radius: 0.5rem;

  > aside {
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
  }

  > .multiselect {
    min-height: 2.5rem;
    > .multiselect__tags {
      border-radius: 0.25rem;
      min-height: 1.5rem;
      margin: 0.25rem;
      padding: 2px 40px 2px 0;

      > .multiselect__placeholder {
        margin-top: 4px;
        margin-bottom: 4px;
        padding-left: 2px;
      }

      > .multiselect__single {
        margin-top: 4px;
        margin-bottom: 4px;
      }
      > .multiselect__input {
        margin-top: 4px;
        margin-bottom: 4px;
        font-size: 14px;
      }
    }
  }
`

export default {
  props: {
    selectables: {
      type: Array,
      required: true,
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    clearOnSelect: {
      type: Boolean,
      default: true,
    },
    trackBy: {
      type: String,
      default: 'id',
    },
    label: {
      type: String,
      default: 'label',
    },
    openDirection: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    closedPlaceholder: {
      type: String,
      default: 'Click to select',
    },
    openedPlaceholder: {
      type: String,
      default: 'type to search',
    },
  },
  components: {
    SearchAndSelectMolecule,
    Multiselect,
  },
  data() {
    return {
      multiselectIsOpen: false,
    }
  },
  computed: {
    hasSlotAside() {
      return !!this.$slots?.aside
    },
    placerholder() {
      return this.multiselectIsOpen ? this.openedPlaceholder : this.closedPlaceholder
    },
  },
}
</script>
