<template>
  <InheritedByAcquistionAtom>
    <TextOverflowEllipsisAtom class="label" :text="inherited.label" />
    <div class="product">
      <span class="muted">via</span>
      <EntityLinkAtom :type="'Product'" :id="inherited?.product?.id" :label="`${inherited?.product?.name} - ${inherited?.tierLevel}`" />
    </div>
    <div class="acquisition">
      <span class="muted">acquired by</span>
      <EntityLinkAtom :type="'Acquisition'" :id="inherited?.acquisition?.id" :label="inherited?.organization?.name" />
    </div>
  </InheritedByAcquistionAtom>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import { TextOverflowEllipsisAtom } from '@common/components'

import EntityLinkAtom from '@/components/Atomic/Atoms/EntityLinkAtom'

const InheritedByAcquistionAtom = styled('div')`
  border-radius: 3px;
  padding: 0.25rem;
  background: ${({ theme }) => theme.colors.archonWhiteTransparent};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content min-content;
  grid-gap: 0.5rem;
  align-items: center;
  .label {
    overflow: hidden;
    grid-row: span 2;
  }
  .muted {
    color: ${({ theme }) => theme.colors.muted};
    margin-right: 0.5rem;
  }
`

export default {
  props: {
    inherited: {
      type: Object,
      required: true,
    },
  },
  components: {
    InheritedByAcquistionAtom,
    EntityLinkAtom,
    TextOverflowEllipsisAtom,
  },
}
</script>
