<template>
  <UserAccessTemplate>
    <UserAccessOrganism :userId="userId" :type="'permission'" />
    <UserAccessOrganism :userId="userId" :type="'assetDimension'" />
    <UserAccessOrganism :userId="userId" :type="'widgetType'" />
  </UserAccessTemplate>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import UserAccessOrganism from '../Organisms/UserAccessOrganism'

const UserAccessTemplate = styled('div')``

export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  components: {
    UserAccessTemplate,
    UserAccessOrganism,
  },
}
</script>
