var render = function () {
  var _vm$relations;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('GridMolecule', {
    attrs: {
      "gap": 1
    }
  }, [_c('RelationManagerListMolecule', {
    attrs: {
      "title": "Manual ".concat(_vm.relationsKey, "s (").concat((_vm$relations = _vm.relations) === null || _vm$relations === void 0 ? void 0 : _vm$relations.length, ")"),
      "relations": _vm.relations,
      "itemLabelKey": 'name',
      "addables": _vm.addables
    },
    on: {
      "select": _vm.handleSelect,
      "remove": _vm.handleRemove
    }
  }), _c('EntityListMolecule', [_c('LabelAtom', {
    attrs: {
      "slot": "header",
      "label": "Inherited ".concat(_vm.relationsKey, "s (").concat(_vm.inheritedItems.length, ")")
    },
    slot: "header"
  }), _vm._l(_vm.inheritedItems, function (inheritedItem, i) {
    return _c('InheritedByAcquistionAtom', {
      key: i,
      attrs: {
        "inherited": inheritedItem
      }
    });
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }