var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('EntityNotFoundAtom', [_c('span', [_c('span', {
    staticClass: "entity-type"
  }, [_vm._v(_vm._s(_vm.entityType))]), _c('span', {
    staticClass: "muted"
  }, [_vm._v("with the id")]), _c('span', {
    staticClass: "entity-id"
  }, [_vm._v("'" + _vm._s(_vm.entityId) + "'")]), _c('span', {
    staticClass: "muted"
  }, [_vm._v("was not found.")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }