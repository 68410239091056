<template>
  <RelationManagerListMolecule
    :title="`Organizations (${userOrganizations.length})`"
    :itemComponent="itemComponent"
    :relations="userOrganizations"
    :addables="addableOrganizations"
    :addablesLoading="$apollo.queries.loading"
    :itemLabelKey="'label'"
    @select="createMembership"
    @update="updateMembership"
    @remove="confirmDeleteMembership"
  />
</template>

<script>
import { FlashMessages } from '@common/singletons'
import RelationManagerListMolecule from '@/components/Atomic/Molecules/RelationManagerListMolecule.vue'

import USER_QUERY from '#/graphql/user/show.gql'
import LIST_ORGANIZATIONS_QUERY from '#/graphql/organizations/listMinimal.gql'
import CREATE_ORGANIZATION_MEMBERSHIP_MUTATION from '#/graphql/user/organizationMembership/create.gql'
import UPDATE_ORGANIZATION_MEMBERSHIP_MUTATION from '#/graphql/user/organizationMembership/update.gql'
import DELETE_ORGANIZATION_MEMBERSHIP_MUTATION from '#/graphql/user/organizationMembership/delete.gql'

import UserOrganizationAtom from '../Atoms/UserOrganizationAtom'

export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  components: {
    RelationManagerListMolecule,
    // UserOrganizationAtom,
  },
  data() {
    return {
      talpaRoleGroups: [],
      organizations: [],
      searchString: '',
      itemComponent: UserOrganizationAtom,
    }
  },
  computed: {
    userOrganizations() {
      return this.user?.memberships.map(m => ({
        ...m,
        label: `${m.organization.alias} - ${m.organization.name}`,
        linkTo: { name: 'organization', params: { id: m.organization.id } },
      }))
    },
    addableOrganizations() {
      const userOrganizationIds = this.userOrganizations.map(m => m.organization.id)
      return this.organizations
        .reduce((acc, organization) => {
          if (!userOrganizationIds.includes(organization.id)) {
            acc.push({
              ...organization,
              label: `${organization.alias} - ${organization.name}`,
            })
          }
          return acc
        }, [])
        .sort((a, b) => a.label.localeCompare(b.label))
    },
  },
  methods: {
    async createMembership($event) {
      try {
        await this.$apollo.mutate({
          mutation: CREATE_ORGANIZATION_MEMBERSHIP_MUTATION,
          variables: {
            data: {
              userId: this.userId,
              organization: {
                connect: {
                  id: $event.id,
                },
              },
              role: 'USER',
            },
          },
          update: (store, { data }) => {
            const { user } = store.readQuery({
              query: USER_QUERY,
              variables: {
                where: {
                  id: this.userId,
                },
              },
            })
            user.memberships.push(data.createOrganizationMembership)
            store.writeQuery({
              query: USER_QUERY,
              variables: {
                where: {
                  id: this.$route.params.id,
                },
              },
              data: {
                user,
              },
            })
          },
        })
        FlashMessages.$emit('success', `Successfully added user to organization '${$event.name}'`, {
          timeout: 1500,
        })
        this.$apollo.queries.user.refetch()
      } catch (err) {
        FlashMessages.$emit('error', err)
        throw err
      }
    },
    async updateMembership({ id, role, organization }) {
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_ORGANIZATION_MEMBERSHIP_MUTATION,
          variables: {
            data: {
              role,
            },
            where: {
              id,
            },
          },
          update: (store, { data }) => {
            const { user } = store.readQuery({
              query: USER_QUERY,
              variables: {
                where: {
                  id: this.userId,
                },
              },
            })
            const membership = user.memberships.find(f => f.id === data.updateOrganizationMembership?.id)
            membership.role = data.updateOrganizationMembership.role
            store.writeQuery({
              query: USER_QUERY,
              variables: {
                where: {
                  id: this.userId,
                },
              },
              data: {
                user,
              },
            })
          },
        })
        FlashMessages.$emit('success', `Successfully set user membership role for organization '${organization.name}' to ${role}`, {
          timeout: 1500,
        })
        this.$apollo.queries.user.refetch()
      } catch (err) {
        FlashMessages.$emit('error', err)
        throw err
      }
    },
    confirmDeleteMembership($event) {
      this.$root.$emit('activateOverlay', 'ConfirmDeleteOverlay', {
        type: 'Membership',
        onConfirm: this.deleteMembership,
        onConfirmArgs: $event,
      })
    },
    async deleteMembership($event) {
      try {
        await this.$apollo.mutate({
          mutation: DELETE_ORGANIZATION_MEMBERSHIP_MUTATION,
          variables: {
            where: {
              id: $event.id,
            },
          },
          update: (store, { data }) => {
            const { user } = store.readQuery({
              query: USER_QUERY,
              variables: {
                where: {
                  id: this.userId,
                },
              },
            })
            user.memberships = user.memberships.filter(f => f.id !== data.deleteOrganizationMembership?.id)
            store.writeQuery({
              query: USER_QUERY,
              variables: {
                where: {
                  id: this.userId,
                },
              },
              data: {
                user,
              },
            })
          },
        })
        FlashMessages.$emit('success', `Successfully removed user from organization '${$event.organization.name}'`, {
          timeout: 1500,
        })
        await this.$apollo.queries.user.refetch()
        return true
      } catch (err) {
        FlashMessages.$emit('error', err)
        throw err
      }
    },
  },
  apollo: {
    user: {
      query: USER_QUERY,
      variables() {
        return {
          where: {
            id: this.userId,
          },
        }
      },
    },
    organizations: {
      query: LIST_ORGANIZATIONS_QUERY,
      variables() {
        if (this.searchString === '') {
          return undefined
        }
        return {
          where: {
            OR: [
              {
                name_contains: this.searchString,
              },
              {
                alias_contains: this.searchString,
              },
            ],
          },
        }
      },
    },
  },
}
</script>
