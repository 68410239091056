<template>
  <ApolloQuery :query="require('#/graphql/user/show.gql')" :variables="variables">
    <template v-slot="{ result: { loading, error, data } }">
      <TalpaLoaderWrapper :transparent="true" v-if="loading" />
      <ErrorAtom v-else-if="error" :error="error" />
      <UserAttributesMolecule v-else-if="data" :user="data.user" />
      <EntityNotFoundAtom v-else :entityType="'User'" :entityId="userId" />
    </template>
  </ApolloQuery>
</template>

<script>
import { TalpaLoaderWrapper, ErrorAtom } from '@common/components'

import EntityNotFoundAtom from '@/components/Atomic/Atoms/EntityNotFoundAtom'
import UserAttributesMolecule from '../Molecules/UserAttributesMolecule'

export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  components: {
    TalpaLoaderWrapper,
    ErrorAtom,
    UserAttributesMolecule,
    EntityNotFoundAtom,
  },
  computed: {
    variables() {
      return {
        where: {
          id: this.userId,
        },
      }
    },
  },
}
</script>
