var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SearchAndSelectMolecule', {
    staticClass: "search-and-select"
  }, [_vm.hasSlotAside ? _c('aside', [_vm._t("aside")], 2) : _vm._e(), _c('Multiselect', {
    attrs: {
      "options": _vm.selectables,
      "show-labels": false,
      "searchable": true,
      "internalSearch": false,
      "trackBy": _vm.trackBy,
      "label": _vm.label,
      "placeholder": _vm.placerholder,
      "closeOnSelect": _vm.closeOnSelect,
      "clearOnSelect": _vm.clearOnSelect,
      "openDirection": _vm.openDirection,
      "disabled": _vm.disabled,
      "loading": _vm.loading
    },
    on: {
      "select": function select($event) {
        return _vm.$emit('select', $event);
      },
      "search-change": function searchChange($event) {
        return _vm.$emit('search-change', $event);
      },
      "open": function open($event) {
        _vm.multiselectIsOpen = true;
      },
      "close": function close($event) {
        _vm.multiselectIsOpen = false;
      }
    }
  }, [_c('span', {
    attrs: {
      "slot": "noOptions"
    },
    slot: "noOptions"
  }, [_vm._v("No options.")]), _c('span', {
    attrs: {
      "slot": "noResult"
    },
    slot: "noResult"
  }, [_vm._v("No results.")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }