<template>
  <DateTimeMolecule :class="{ 'with-copy-icon': hasCopyToClipboard }">
    <span class="formatted-datetime">{{ formattedDateTime }}</span>
    <CopyToClipboardAtom v-if="hasCopyToClipboard" :value="value" />
  </DateTimeMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { DateTime } from 'luxon'

import { CopyToClipboardAtom } from '@common/components'
import { flexCenter } from '@styles/mixins'

const DateTimeMolecule = styled('span')`
  ${flexCenter}
  &.with-copy-icon {
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-gap: 0.25rem;
  }
`

export default {
  inject: ['uiSettings'],
  props: {
    value: {
      type: String,
      required: true,
      validator(value) {
        return DateTime.fromISO(value).isValid
      },
    },
    hasCopyToClipboard: {
      type: Boolean,
      default: false,
    },
    format: {
      type: Object,
      default: () => {
        return DateTime.DATETIME_MED_WITH_SECONDS
      },
    },
  },
  components: {
    DateTimeMolecule,
    CopyToClipboardAtom,
  },
  computed: {
    locale() {
      return (this.uiSettings?.language ?? 'DE_DE').toLowerCase().replace('_', '-')
    },
    dateTime() {
      return DateTime.fromISO(this.value)
    },
    formattedDateTime() {
      return this.dateTime.toLocaleString(this.format, {
        locale: this.locale,
      })
    },
  },
}
</script>
