<template>
  <ModelManagerTableMolecule>
    <header>
      <slot v-if="hasHeaderSlot" name="header" />
      <strong v-if="hasHeaderSlot === false && title !== undefined" class="title">{{ title }}</strong>
      <CountCircle v-if="hasHeaderSlot === false && count !== undefined" :count="count" />
    </header>
    <VeTable
      :max-height="maxHeight"
      :columns="columnsWithActions"
      :table-data="rows"
      :border-x="true"
      :border-y="true"
      :fixed-header="true"
      :row-key-field-name="rowKeyFieldName"
      :row-style-option="rowStyleOption"
      :cell-style-option="cellStyleOption"
      :checkbox-option="checkboxOption"
      :cellSelectionOption="cellSelectionOption"
      :virtual-scroll-option="virtualScrollOption"
    />
    <slot name="footer" />
  </ModelManagerTableMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { VeTable } from 'vue-easytable'
import { CountCircle } from '@common/components'
import 'vue-easytable/libs/theme-dark/index.css'

import RelationManagerTableDefaultActionsAtom from '../Atoms/RelationManagerTableDefaultActionsAtom'

const ModelManagerTableMolecule = styled('div')`
  > header,
  > footer {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem;
    background: ${({ theme }) => theme.colors.archonDarkGrey};
  }
`

export default {
  props: {
    title: {
      type: String,
    },
    count: {
      type: Number,
    },
    columns: {
      type: Array,
      required: true,
    },
    hasDefaultActions: {
      type: Boolean,
      default: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    rowStyleOption: {
      type: Object,
      default: () => {
        return {
          clickHighlight: false,
        }
      },
    },
    cellStyleOption: {
      type: Object,
    },
    cellSelectionOption: {
      type: Object,
      default: () => {
        return {
          enable: false,
        }
      },
    },
    rowKeyFieldName: {
      type: String,
      default: 'id',
    },
    checkboxOption: {
      type: Object,
      default() {
        return {
          defaultSelectedRowKeys: [],
          selectedRowChange: $event => {
            this.$emit('selected-row-change', $event)
          },
          selectedAllChange: $event => {
            this.$emit('select-all-change', $event)
          },
        }
      },
    },
    virtualScrollOption: {
      type: Object,
      default() {
        return {
          enable: this.rows?.length > 100,
        }
      },
    },
    maxHeight: {
      type: Number,
      default: 500,
    },
    hasEdit: {
      type: Boolean,
      default: true,
    },
    hasCopy: {
      type: Boolean,
      default: false,
    },
    hasDelete: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    ModelManagerTableMolecule,
    VeTable,
    CountCircle,
  },
  computed: {
    hasHeaderSlot() {
      return !!this.$slots.header
    },
    hasFooterSlot() {
      return !!this.$slots.footer
    },
    columnsWithActions() {
      if (!this.hasDefaultActions) {
        return this.columns
      }
      const defaultActions = [
        {
          title: 'Actions',
          align: 'center',
          key: 'actions',
          renderBodyCell: ({ row }) => {
            return (
              <RelationManagerTableDefaultActionsAtom
                row={row}
                hasEdit={this.hasEdit}
                onEdit={() => this.$emit('edit', row.id)}
                onCopy={() => this.$emit('copy', row.id)}
                onDelete={() => this.$emit('delete', row.id)}
              />
            )
          },
        },
      ]
      return this.columns.concat(defaultActions)
    },
  },
}
</script>
