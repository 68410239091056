var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('GridMolecule', _vm._l(_vm.fields, function (field) {
    return _c('EditableFieldMolecule', {
      key: field.id,
      attrs: {
        "label": field.label,
        "value": field.value,
        "isIdLike": field.isIdLike,
        "isDateTime": field.isDateTime,
        "hasCopyToClipboard": field.hasCopyToClipboard,
        "isReadonly": true
      }
    });
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }