<template>
  <GridMolecule>
    <EditableFieldMolecule
      v-for="field in fields"
      :key="field.id"
      :label="field.label"
      :value="field.value"
      :isIdLike="field.isIdLike"
      :isDateTime="field.isDateTime"
      :hasCopyToClipboard="field.hasCopyToClipboard"
      :isReadonly="true"
    />
  </GridMolecule>
</template>

<script>
import GridMolecule from '@/components/Atomic/Molecules/GridMolecule'
import EditableFieldMolecule from '@/components/Atomic/Molecules/EditableFieldMolecule'

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  components: {
    GridMolecule,
    EditableFieldMolecule,
  },
  computed: {
    fields() {
      return [
        {
          id: 'id',
          label: 'ID',
          isIdLike: true,
          hasCopyToClipboard: true,
        },
        {
          id: 'email',
          label: 'Email',
          hasCopyToClipboard: true,
        },
        {
          id: 'username',
          label: 'Username',
          hasCopyToClipboard: true,
        },
        {
          id: 'roles',
          label: 'Roles',
        },
        {
          id: 'firstName',
          label: 'First name',
        },
        {
          id: 'lastName',
          label: 'Last name',
        },
        {
          id: 'requiredActions',
          label: 'Pending actions',
        },
        {
          id: 'createdAt',
          label: 'Created at',
          isDateTime: true,
          hasCopyToClipboard: true,
        },
      ].map(field => {
        const valueRaw = this.user[field.id]
        let value = valueRaw
        if (Array.isArray(value)) {
          value = value.join(', ')
        }
        return {
          ...field,
          valueRaw,
          value,
        }
      })
    },
  },
}
</script>
