<template>
  <TalpaLoaderWrapper :transparent="true" v-if="$apollo.queries.user.loading" />
  <div class="errors" v-else-if="hasErrors">
    <ErrorAtom v-for="(error, i) in errors" :error="error" :key="i" />
  </div>
  <ModelManagerTableMolecule
    v-else-if="user"
    :title="`Dashboards shared with user`"
    :count="rows.length"
    :columns="columns"
    :hasEdit="false"
    :rows="rows"
    @selected-row-change="handleSelectedRowChange"
    @select-all-change="handleSelectAllChange"
    @delete="confirmDeleteDashboardShare"
  >
    <UserDashboardsFooterMolecule slot="footer">
      <IconButtonAtom :disabled="selectedRowIds.length === 0" @click="confirmDeleteSelectedDashboardShares">
        <TrashIcon v-if="false" />
        <span>delete selected</span>
      </IconButtonAtom>
      <SearchAndSelectMolecule
        :selectables="shareableDashboards"
        :loading="shareableDashboardsLoading"
        @select="shareSelectedDashboard"
        @search-change="shareableDashboardsSearchQuery = $event"
      >
        <span slot="aside">Copy</span>
      </SearchAndSelectMolecule>
    </UserDashboardsFooterMolecule>
  </ModelManagerTableMolecule>
</template>

<script>
import { TalpaLoaderWrapper, ErrorAtom } from '@common/components'
import { FlashMessages } from '@common/singletons'

import ModelManagerTableMolecule from '@/components/Atomic/Molecules/ModelManagerTableMolecule'
import DateTimeMolecule from '@/components/Atomic/Molecules/DateTimeMolecule'
import IconButtonAtom from '@/components/Atomic/Atoms/IconButtonAtom'
import SearchAndSelectMolecule from '@/components/Atomic/Molecules/SearchAndSelectMolecule'
import UserDashboardsFooterMolecule from '../Molecules/UserDashboardsFooterMolecule'

import DASHBOARDS_SHARED_WITH_USER from '#/graphql/user/dashboardsSharedWithUser.gql'
import DASHBOARDS_CONNECTION_QUERY from '#/graphql/operations/dashboards/connectionQuery/dashboardsConnectionMinimal.gql'
import DELETE_DASHBOARD_SHARE from '#/graphql/operations/dashboard/share/deleteDashboardShare.gql'
import CREATE_DASHBOARD_SHARE_MUTATION from '#/graphql/operations/dashboard/share/createDashboardShare.gql'

export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  components: {
    TalpaLoaderWrapper,
    ErrorAtom,
    ModelManagerTableMolecule,
    IconButtonAtom,
    SearchAndSelectMolecule,
    UserDashboardsFooterMolecule,
  },
  data() {
    return {
      user: null,
      errors: [],
      dashboardsAddable: [],
      selectedRowIds: [],
      shareableDashboards: [],
      shareableDashboardsSearchQuery: '',
      shareableDashboardsLoading: false,
    }
  },
  computed: {
    hasErrors() {
      return this.errors.length > 0
    },
    variables() {
      return {
        where: {
          id: this.userId,
        },
      }
    },
    columns() {
      const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('')
      return [
        {
          field: '',
          type: 'checkbox',
          title: '',
          width: 50,
          align: 'center',
        },
        {
          field: 'title',
          title: 'Title',
          align: 'left',
        },
        {
          title: 'Owner',
          renderBodyCell: ({ row }) => {
            return (
              <router-link to={{ name: 'user', params: { id: row.ownerUserId }, query: { section: 'dashboards' } }}>
                {row.ownerUsername}
              </router-link>
            )
          },
        },
        {
          field: 'sharedWithCount',
          title: 'Shared with',
          align: 'center',
        },
        {
          title: 'Updated At',
          renderBodyCell: ({ row }) => {
            return <DateTimeMolecule value={row.dashboard.updatedAt} />
          },
        },
        {
          title: 'Created At',
          renderBodyCell: ({ row }) => {
            return <DateTimeMolecule value={row.dashboard.createdAt} />
          },
        },
      ].map((m, i) => ({ ...m, key: alphabet[i] }))
    },
    rows() {
      return this.user?.dashboardShares.map(m => ({
        ...m,
        title: m.dashboard.title,
        sharedWithCount: m.dashboard.sharedWithCount,
        ownerUserId: m.dashboard.ownerUserId,
        ownerUsername: m.dashboard.owner.name,
      }))
    },
  },
  methods: {
    handleSelectedRowChange($event) {
      this.selectedRowIds = $event.selectedRowKeys
    },
    handleSelectAllChange($event) {
      this.selectedRowIds = $event.selectedRowKeys
    },
    async shareSelectedDashboard({ id, owner }) {
      await this.$apollo.mutate({
        mutation: CREATE_DASHBOARD_SHARE_MUTATION,
        variables: {
          data: {
            dashboard: {
              connect: {
                id,
              },
            },
            canWrite: false, // TODO: modify this based on logic
            sharedWithUserId: this.userId,
          },
          where: {
            srcUserId: owner.id,
            targetUserId: this.userId,
          },
        },
      })
      await this.$apollo.queries.user.refetch()
      await this.$apollo.queries.dashboards.refetch()
    },
    async confirmDeleteSelectedDashboardShares() {
      this.$root.$emit('activateOverlay', 'ConfirmDeleteOverlay', {
        confirmText: `Do you really want to delete the ${this.selectedRowIds?.length} selected DashboardShares? This cannot be made undone!`,
        onConfirm: this.deleteSelectedDashboardShares,
      })
    },
    async deleteSelectedDashboardShares() {
      for await (const dashboardId of this.selectedRowIds) {
        await this.deleteDashboardShare({ id: dashboardId, refetch: false })
      }
      await this.$apollo.queries.user.refetch()
      return true
    },
    async deleteDashboardShare({ id, refetch = true }) {
      try {
        await this.$apollo.mutate({
          mutation: DELETE_DASHBOARD_SHARE,
          variables: {
            where: {
              id,
            },
          },
        })
        if (refetch) {
          await this.$apollo.queries.user.refetch()
        }
        return true
      } catch (err) {
        FlashMessages.$emit('error', err)
      }
    },
    async confirmDeleteDashboardShare(dashboardShare) {
      this.$root.$emit(
        'activateOverlay',
        'ConfirmDeleteOverlay',
        {
          type: 'DashboardShare',
          instance: dashboardShare.dashboard,
          labelKey: 'title',
          onConfirm: this.deleteDashboardShare,
          onConfirmArgs: { id: dashboardShare, refetch: true },
        },
        this.onCloseSettings,
      )
    },
  },
  apollo: {
    dashboards: {
      query: DASHBOARDS_CONNECTION_QUERY,
      debounce: 100,
      fetchPolicy: 'network-only',
      variables() {
        const where = {
          shares_every: {
            sharedWithUserId_not: this.userId,
          },
        }
        if (this.shareableDashboardsSearchQuery?.length > 0) {
          where.title_contains = this.shareableDashboardsSearchQuery
        }
        return {
          first: 30,
          where,
        }
      },
      manual: true,
      result({ data, loading }) {
        this.shareableDashboardsLoading = loading
        this.shareableDashboards =
          data.dashboardsConnection?.edges?.map(m => ({ ...m.node, label: `${m.node?.owner?.email} - ${m.node?.title}` })) ?? []
      },
    },
    user: {
      query: DASHBOARDS_SHARED_WITH_USER,
      variables() {
        return {
          where: {
            id: this.userId,
          },
        }
      },
      manual: true,
      result({ data, error, errors }) {
        this.errors = errors ?? []
        if (error) {
          this.errors.push(error)
        }
        this.user = data?.user
      },
    },
  },
}
</script>
