var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('GridMolecule', [_c('UserAttributesOrganism', {
    staticClass: "span-2",
    attrs: {
      "userId": _vm.userId
    }
  }), _c('UserOrganisationsOrganism', {
    attrs: {
      "userId": _vm.userId
    }
  }), _c('UserSubsidiariesOrganism', {
    attrs: {
      "userId": _vm.userId
    }
  }), _c('UserTalpaRolesOrganism', {
    attrs: {
      "userId": _vm.userId
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }