<template>
  <UserOrganizationAtom>
    <router-link :to="item.linkTo">
      <TextOverflowEllipsisAtom class="label" :text="label ?? item.id" />
    </router-link>
    <div />
    <Multiselect
      class="select-role"
      track-by="id"
      :value="selectedRole"
      :label="'label'"
      :options="roles"
      :searchable="false"
      :multiple="false"
      :showLabels="false"
      @select="$emit('update', { ...item, role: $event.id })"
    />
    <IconButtonAtom v-if="hasRemove" @click="$emit('remove')">
      <XIcon size="16" />
    </IconButtonAtom>
  </UserOrganizationAtom>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { XIcon } from 'vue-feather-icons'
import Multiselect from 'vue-multiselect'

import { TextOverflowEllipsisAtom } from '@common/components'

import IconButtonAtom from '@/components/Atomic/Atoms/IconButtonAtom'

const UserOrganizationAtom = styled('div')`
  border-radius: 3px;
  padding: 0.25rem;
  background: ${({ theme }) => theme.colors.archonBlueTransparent};
  display: grid;
  grid-template-columns: min-content 1fr min-content min-content;
  grid-template-rows: min-content;
  grid-gap: 1rem;
  align-items: center;
  > .label {
    overflow: hidden;
  }
  > .select-role {
    min-width: 8rem;
  }
`

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    labelKey: {
      type: String,
      default: 'label',
    },
    hasRemove: {
      type: Boolean,
      default: true,
    },
    linkTo: {
      type: Object,
    },
  },
  components: {
    UserOrganizationAtom,
    TextOverflowEllipsisAtom,
    IconButtonAtom,
    XIcon,
    Multiselect,
  },
  data() {
    return {
      roles: ['ADMIN', 'USER'].map(m => ({
        id: m,
        label: m,
      })),
    }
  },
  computed: {
    selectedRole() {
      return this.roles.find(f => f.id === this.item.role)
    },
    label() {
      return this.item[this.labelKey]
    },
  },
}
</script>
