var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TabRoutesMolecule', _vm._l(_vm.routes, function (route) {
    return _c('router-link', {
      key: route.id,
      class: {
        'is-forced-active': route.isForcedActive
      },
      attrs: {
        "to": route.to
      }
    }, [_vm._v(" " + _vm._s(route.label) + " ")]);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }