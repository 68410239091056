<template>
  <UserDashboardsTemplate>
    <UserOwnedDashboardsOrganism :userId="userId" />
    <DashboardsSharedWithUserOrganism :userId="userId" />
  </UserDashboardsTemplate>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import UserOwnedDashboardsOrganism from '../Organisms/UserOwnedDashboardsOrganism'
import DashboardsSharedWithUserOrganism from '../Organisms/DashboardsSharedWithUserOrganism'

const UserDashboardsTemplate = styled('div')`
  display: grid;
  grid-gap: 0.5rem;
  background: ${({ theme }) => theme.colors.archonBlack};
`

export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  components: {
    UserDashboardsTemplate,
    UserOwnedDashboardsOrganism,
    DashboardsSharedWithUserOrganism,
  },
}
</script>
