<template>
  <TalpaLoaderWrapper :transparent="true" v-if="$apollo.queries.user.loading" />
  <div class="errors" v-else-if="hasErrors">
    <ErrorAtom v-for="(error, i) in errors" :error="error" :key="i" />
  </div>
  <ModelManagerTableMolecule
    v-else-if="user"
    :title="`Dashboards owned by user`"
    :count="rows.length"
    :columns="columns"
    :hasEdit="false"
    :rows="rows"
    @selected-row-change="handleSelectedRowChange"
    @select-all-change="handleSelectAllChange"
    @delete="confirmDeleteDashboard"
  >
    <UserDashboardsFooterMolecule slot="footer">
      <IconButtonAtom :disabled="selectedRowIds.length === 0" @click="confirmDeleteSelectedDashboards">
        <TrashIcon v-if="false" />
        <span>delete selected</span>
      </IconButtonAtom>
      <SearchAndSelectMolecule
        :selectables="copyableDashboards"
        :loading="copyableDashboardsLoading"
        @select="copySelectedDashboard"
        @search-change="copyableDashboardsSearchQuery = $event"
      >
        <span slot="aside">Copy</span>
      </SearchAndSelectMolecule>
    </UserDashboardsFooterMolecule>
  </ModelManagerTableMolecule>
</template>

<script>
import { TalpaLoaderWrapper, ErrorAtom } from '@common/components'
import { FlashMessages } from '@common/singletons'
import { TrashIcon } from 'vue-feather-icons'

import USER_QUERY from '#/graphql/user/show.gql'
import DASHBOARDS_CONNECTION_QUERY from '#/graphql/operations/dashboards/connectionQuery/dashboardsConnectionMinimal.gql'
import DELETE_DASHBOARD from '#/graphql/operations/dashboard/deleteDashboard.gql'

import CopyDashboardMixin from '../DuplicateUser/CopyDashboardMixin.js'
import IconButtonAtom from '@/components/Atomic/Atoms/IconButtonAtom'
import ModelManagerTableMolecule from '@/components/Atomic/Molecules/ModelManagerTableMolecule'
import DateTimeMolecule from '@/components/Atomic/Molecules/DateTimeMolecule'
import SearchAndSelectMolecule from '@/components/Atomic/Molecules/SearchAndSelectMolecule'
import UserDashboardsFooterMolecule from '../Molecules/UserDashboardsFooterMolecule'

export default {
  mixins: [CopyDashboardMixin],
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  components: {
    TalpaLoaderWrapper,
    ErrorAtom,
    ModelManagerTableMolecule,
    IconButtonAtom,
    TrashIcon,
    SearchAndSelectMolecule,
    UserDashboardsFooterMolecule,
  },
  data() {
    return {
      user: null,
      errors: [],
      dashboardsAddable: [],
      dashboardSearchLoading: false,
      selectedRowIds: [],
      copyableDashboards: [],
      copyableDashboardsSearchQuery: '',
      copyableDashboardsLoading: false,
    }
  },
  computed: {
    hasErrors() {
      return this.errors.length > 0
    },
    variables() {
      return {
        where: {
          id: this.userId,
        },
      }
    },
    columns() {
      const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('')
      return [
        {
          field: '',
          type: 'checkbox',
          title: '',
          width: 50,
          align: 'center',
        },
        {
          field: 'title',
          title: 'Title',
          align: 'left',
        },
        {
          field: 'sharedWithCount',
          title: 'Shared with',
          align: 'center',
        },
        {
          title: 'Updated At',
          renderBodyCell: ({ row }) => {
            return <DateTimeMolecule value={row.updatedAt} />
          },
        },
        {
          title: 'Created At',
          renderBodyCell: ({ row }) => {
            return <DateTimeMolecule value={row.createdAt} />
          },
        },
      ].map((m, i) => ({ ...m, key: alphabet[i] }))
    },
    rows() {
      return this.user?.dashboards ?? []
    },
  },
  methods: {
    handleSelectedRowChange($event) {
      this.selectedRowIds = $event.selectedRowKeys
    },
    handleSelectAllChange($event) {
      this.selectedRowIds = $event.selectedRowKeys
    },
    async copySelectedDashboard({ id }) {
      await this.copyDashboard(id, this.userId, false)
      this.$apollo.queries.user.refetch()
    },
    async confirmDeleteSelectedDashboards() {
      this.$root.$emit('activateOverlay', 'ConfirmDeleteOverlay', {
        confirmText: `Do you really want to delete the ${this.selectedRowIds?.length} selected Dashboards? This cannot be made undone!`,
        onConfirm: this.deleteSelectedDashboards,
      })
    },
    async deleteSelectedDashboards() {
      for await (const dashboardId of this.selectedRowIds) {
        await this.deleteDashboard({ id: dashboardId, refetch: false })
      }
      await this.$apollo.queries.user.refetch()
      return true
    },
    async deleteDashboard({ id, refetch = true }) {
      try {
        await this.$apollo.mutate({
          mutation: DELETE_DASHBOARD,
          variables: {
            where: {
              id,
            },
          },
        })
        if (refetch) {
          await this.$apollo.queries.user.refetch()
        }
        return true
      } catch (err) {
        FlashMessages.$emit('error', err)
      }
    },
    async confirmDeleteDashboard(dashboard) {
      this.$root.$emit(
        'activateOverlay',
        'ConfirmDeleteOverlay',
        {
          type: 'Dashboard',
          instance: dashboard,
          labelKey: 'title',
          onConfirm: this.deleteDashboard,
          onConfirmArgs: { id: dashboard, refetch: true },
        },
        this.onCloseSettings,
      )
    },
  },
  apollo: {
    dashboards: {
      query: DASHBOARDS_CONNECTION_QUERY,
      debounce: 100,
      fetchPolicy: 'network-only',
      variables() {
        const where = {}
        if (this.copyableDashboardsSearchQuery?.length > 0) {
          where.title_contains = this.copyableDashboardsSearchQuery
        }
        return {
          first: 30,
          where,
        }
      },
      manual: true,
      result({ data, loading }) {
        this.copyableDashboardsLoading = loading
        this.copyableDashboards =
          data.dashboardsConnection?.edges?.map(m => ({ ...m.node, label: `${m.node?.owner?.email} - ${m.node?.title}` })) ?? []
      },
    },
    user: {
      query: USER_QUERY,
      variables() {
        return {
          where: {
            id: this.userId,
          },
        }
      },
      manual: true,
      result({ data, error, errors }) {
        this.errors = errors ?? []
        if (error) {
          this.errors.push(error)
        }
        this.user = data?.user
      },
    },
  },
}
</script>
