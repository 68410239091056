<template>
  <PanelMolecule :title="title" :hasMargin="true" :stickyHeader="true" :noGap="true">
    <TabRoutesMolecule slot="subheader" :routes="routes" />
    <template slot="main">
      <TalpaLoaderWrapper v-if="$apollo.loading" />
      <UserOverviewTemplate v-else-if="this.$route?.query?.section === undefined" :userId="$route.params.id" />
      <UserAccessTemplate v-else-if="this.$route?.query?.section === 'access'" :userId="$route.params.id" />
      <UserDashboardsTemplate v-else-if="this.$route?.query?.section === 'dashboards'" :userId="$route.params.id" />
    </template>
  </PanelMolecule>
</template>

<script>
import { TalpaLoaderWrapper } from '@common/components'
import PanelMolecule from '@/components/Atomic/Molecules/PanelMolecule'
import TabRoutesMolecule from '@/components/Atomic/Molecules/TabRoutesMolecule'
import UserOverviewTemplate from './Templates/UserOverviewTemplate'
import UserAccessTemplate from './Templates/UserAccessTemplate'
import UserDashboardsTemplate from './Templates/UserDashboardsTemplate'

import USER_QUERY from '#/graphql/user/show.gql'

export default {
  components: {
    TalpaLoaderWrapper,
    PanelMolecule,
    TabRoutesMolecule,
    UserOverviewTemplate,
    UserAccessTemplate,
    UserDashboardsTemplate,
  },
  data() {
    return {
      user: null,
    }
  },
  computed: {
    title() {
      if (this.$apollo.loading) {
        return ''
      }
      return `User - ${this.user?.username} <${this.user?.email}>`
    },
    routes() {
      return ['Overview', 'Access', 'Dashboards'].map(route => {
        return {
          id: route.toLowerCase(),
          label: route,
          to: {
            query: {
              ...this.$route.query,
              section: route === 'Overview' ? undefined : route.toLowerCase(),
            },
          },
          isForcedActive: route === 'Overview' && this.$route?.query?.section === undefined,
        }
      })
    },
  },
  apollo: {
    user: {
      query: USER_QUERY,
      variables() {
        return {
          where: {
            id: this.$route?.params?.id,
          },
        }
      },
      skip() {
        return !this.$route?.params?.id
      },
    },
  },
}
</script>
