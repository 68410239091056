var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('RelationManagerListMolecule', {
    attrs: {
      "title": "Subsidiaries (".concat(_vm.userSubsidiaries.length, ")"),
      "relations": _vm.userSubsidiaries,
      "addables": _vm.addableSubsidiaries,
      "addablesLoading": _vm.$apollo.queries.loading,
      "itemLabelKey": 'label'
    },
    on: {
      "select": _vm.createMembership,
      "remove": _vm.confirmDeleteMembership
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }