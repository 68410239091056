var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ModelManagerTableMolecule', [_c('header', [_vm.hasHeaderSlot ? _vm._t("header") : _vm._e(), _vm.hasHeaderSlot === false && _vm.title !== undefined ? _c('strong', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _vm.hasHeaderSlot === false && _vm.count !== undefined ? _c('CountCircle', {
    attrs: {
      "count": _vm.count
    }
  }) : _vm._e()], 2), _c('VeTable', {
    attrs: {
      "max-height": _vm.maxHeight,
      "columns": _vm.columnsWithActions,
      "table-data": _vm.rows,
      "border-x": true,
      "border-y": true,
      "fixed-header": true,
      "row-key-field-name": _vm.rowKeyFieldName,
      "row-style-option": _vm.rowStyleOption,
      "cell-style-option": _vm.cellStyleOption,
      "checkbox-option": _vm.checkboxOption,
      "cellSelectionOption": _vm.cellSelectionOption,
      "virtual-scroll-option": _vm.virtualScrollOption
    }
  }), _vm._t("footer")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }