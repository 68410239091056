var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('DateTimeMolecule', {
    class: {
      'with-copy-icon': _vm.hasCopyToClipboard
    }
  }, [_c('span', {
    staticClass: "formatted-datetime"
  }, [_vm._v(_vm._s(_vm.formattedDateTime))]), _vm.hasCopyToClipboard ? _c('CopyToClipboardAtom', {
    attrs: {
      "value": _vm.value
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }