<template>
  <RelationManagerListMolecule
    v-if="isTalpaEmail && isArchon"
    :title="title"
    :relations="userTalpaRoleGroups"
    :addables="talpaRoleGroups"
    :addablesLoading="$apollo.queries.loading"
    :itemLabelKey="'name'"
    @select="addGroupToUser"
    @remove="removeGroupFromUser"
  />
</template>

<script>
import { FlashMessages } from '@common/singletons'
import { getRolesFromToken } from '@common/utils'
import RelationManagerListMolecule from '@/components/Atomic/Molecules/RelationManagerListMolecule.vue'

import USER_QUERY from '#/graphql/user/show.gql'
import TALPA_ROLES_GROUPS_QUERY from '#/graphql/operations/talpaRoleGroups/talpaRoleGroupsQuery.gql'
import UPDATE_USER_TALPA_GROUP_MUTATION from '#/graphql/users/updateUserTalpaGroup.gql'

export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  components: {
    RelationManagerListMolecule,
  },
  data() {
    return {
      title: 'Talpa Roles',
      talpaRoleGroups: [],
    }
  },
  computed: {
    userRoles() {
      return getRolesFromToken(this.$keycloak.token)
    },
    isArchon() {
      return this.userRoles.includes('archon')
    },
    isTalpaEmail() {
      return (this.user?.email ?? '').includes('@talpasolutions') || (this.user?.email ?? '').includes('@talpa-solutions')
    },
    userTalpaRoleGroups() {
      return (this.user?.talpaRoleGroups ?? []).map(group => {
        return {
          ...group,
          label: group?.name ?? '',
        }
      })
    },
  },
  methods: {
    async addGroupToUser(talpaGroup) {
      const userId = this.user?.id ?? null
      const res = await this.$apollo.mutate({
        mutation: UPDATE_USER_TALPA_GROUP_MUTATION,
        variables: {
          action: 'ADD',
          userId: userId,
          groupId: talpaGroup.id,
        },
        update: store => {
          const { user } = store.readQuery({
            query: USER_QUERY,
            variables: {
              where: {
                id: this.$route.params.id,
              },
            },
          })
          user.talpaRoleGroups.push(talpaGroup)

          store.writeQuery({
            query: USER_QUERY,
            variables: {
              where: {
                id: this.$route.params.id,
              },
            },
            data: {
              user,
            },
          })
        },
      })
      if (res !== null && (res?.data?.updateUserTalpaGroup ?? false)) {
        FlashMessages.$emit('success', `Successfully added user to group ${talpaGroup.name}`, {
          timeout: 3000,
        })
      } else {
        FlashMessages.$emit('error', new Error(`Error while adding user to group ${talpaGroup.name}`))
      }
    },
    async removeGroupFromUser({ id, name }) {
      const userId = this.user?.id
      const res = await this.$apollo.mutate({
        mutation: UPDATE_USER_TALPA_GROUP_MUTATION,
        variables: {
          action: 'REMOVE',
          userId: userId,
          groupId: id,
        },
        update: store => {
          const { user } = store.readQuery({
            query: USER_QUERY,
            variables: {
              where: {
                id: this.$route.params.id,
              },
            },
          })
          user.talpaRoleGroups = user.talpaRoleGroups.filter(f => f.id !== id)
          store.writeQuery({
            query: USER_QUERY,
            variables: {
              where: {
                id: this.$route.params.id,
              },
            },
            data: {
              user,
            },
          })
        },
      })
      if (res !== null && (res?.data?.updateUserTalpaGroup ?? false)) {
        FlashMessages.$emit('success', `Successfully removed user to group ${name}`, {
          timeout: 3000,
        })
      } else {
        FlashMessages.$emit('error', new Error(`Error while removing user to group ${name}`))
      }
    },
  },
  apollo: {
    user: {
      query: USER_QUERY,
      variables() {
        return {
          where: {
            id: this.userId,
          },
        }
      },
    },
    talpaRoleGroups: {
      query: TALPA_ROLES_GROUPS_QUERY,
    },
  },
}
</script>
